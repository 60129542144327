import Card from "react-bootstrap/Card";

import { availableLanguages, useLanguage } from "i18n";
import type { Language } from "i18n";
import { useTenantConfig } from "contexts/TenantConfig";
import LocaleSelector from "components/LocaleSelector";
import type { Locale } from "components/LocaleSelector";
import * as images from "assets/images";
import "./AuthPage.scss";

const languageToLocale = (language: Language): Locale => {
  switch (language) {
    case "en":
      return "en-GB";

    case "it":
      return "it-IT";
  }
};

const localeToLanguage = (locale: Locale): Language | null => {
  switch (locale) {
    case "en-GB":
      return "en";

    case "it-IT":
      return "it";

    default:
      return null;
  }
};

const availableLocales = Object.entries(availableLanguages).map(([language]) =>
  languageToLocale(language as Language)
);

type AuthPageProps = {
  children: JSX.Element | JSX.Element[];
};

const AuthPage = ({ children }: AuthPageProps) => {
  const [language, setLanguage] = useLanguage();
  const tenantConfig = useTenantConfig();
  const brandImg = tenantConfig.design.logo || images.brand;

  const handleLanguageChange = (newValue: Locale) => {
    const newLanguage = localeToLanguage(newValue);
    if (newLanguage) {
      setLanguage(newLanguage);
    }
  };

  const locale = languageToLocale(language);

  return (
    <div className="p-3 flex-grow-1 d-flex justify-content-center align-items-center">
      <Card className="border-0 shadow p-3 py-md-4 px-md-5 rounded-3 auth-card">
        <div className="d-flex justify-content-end">
          <LocaleSelector
            value={locale}
            locales={availableLocales}
            onChange={handleLanguageChange}
            displayType="LanguageInitials"
          />
        </div>
        <Card.Img src={brandImg} className="p-3" />
        <Card.Body>{children}</Card.Body>
      </Card>
    </div>
  );
};

export default AuthPage;
